import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  LWrap,
  CJumbotron,
  CSectMedia,
  CSectTitle03,
  CLocalNav,
  CBreadCrumb,
} from '../../../components/_index';
import '../../../assets/_sass/page/suitengu.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: '水天宮のご案内',
            sub: 'INFORMATION ON SUITENGU SHRINE',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/about/suitengu/kv.png',
              },
              imgSp: {
                src: '/assets/images/about/suitengu/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>

      <div className="p_suitengu">
        <section className="u_pt100 u_pb100">
          <LWrap>
            <h2 className="c_headingLv2 u_mb25">安産・子授けの水天宮</h2>
            <p className="c_sectLead">
              ロイヤルパークホテルに隣接する水天宮は、江戸時代から安産・子授けの神様として信仰を集めてきました。
              <br />
              江戸鎮座200年を迎える記念事業として、建替工事を行い新社殿となり、参拝者のための環境改善や震災対策に重点を置き、
              <br />
              ご参拝の皆様により優しく、安心・安全な神社となりました。
            </p>
          </LWrap>
        </section>

        <section>
          <LWrap>
            <CSectMedia
              exClass="u_mbSmall"
              reverse={true}
              data={[
                {
                  img: {
                    src: '/assets/images/about/suitengu/img_suitengu.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/about/suitengu/img_suitengu__sp.png',
                    alt: '',
                  },
                  title: <>子宝いぬ</>,
                  text: (
                    <>
                      愛らしい子犬とそれを見つめる母犬の愛情が伝わってくるよう。
                      <br />
                      周囲を取り巻く十二支のうち、自分の干支を撫でると安産・子授け・お子様の無事成長、家内安全や厄除けなどにご利益があります。
                    </>
                  ),
                },
              ]}
            />
            <p className="c_sectLead u_tac">
              詳しいご案内は、水天宮公式サイトにて
              <br className="u_sp" />
              ご確認ください。
              <br />
              <a href="http://www.suitengu.or.jp" target="_blank">
                http://www.suitengu.or.jp
                <i className="icon-blank"></i>
              </a>
            </p>
          </LWrap>
        </section>

        <section className="u_pt100 u_pb100">
          <LWrap>
            <CSectTitle03 title="お祝いプラン" />
            <CLocalNav
              data={[
                {
                  img: {
                    src: '/assets/images/about/suitengu/img_oiwai.png',
                    alt: '',
                  },
                  link: {
                    href: '/oiwai/anzankigan/',
                  },
                  title: {
                    main: '安産祈願',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/suitengu/img_oiwai02.png',
                    alt: '',
                  },
                  link: {
                    href: '/oiwai/omiyamairi/',
                  },
                  title: {
                    main: 'お宮参り',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/suitengu/img_oiwai03.png',
                    alt: '',
                  },
                  link: {
                    href: '/oiwai/okuizome/',
                  },
                  title: {
                    main: 'お食い初め',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/suitengu/img_oiwai04.png',
                    alt: '',
                  },
                  link: {
                    href: '/oiwai/shichigosan/',
                  },
                  title: {
                    main: '七五三',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/suitengu/img_oiwai05.png',
                    alt: '',
                  },
                  link: {
                    href: '/oiwai/oiwai_plan/',
                  },
                  title: {
                    main: 'お祝い',
                  },
                },
              ]}
            />
          </LWrap>
        </section>
      </div>

      <CBreadCrumb
        data={{
          parent: [
            {
              label: 'ホテルについて',
              path: '/about/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
